import { ConfigProvider, App as AntdAppComponent } from 'antd'
import zhCN from 'antd/locale/zh_CN'
import dayjs from 'dayjs'
import 'dayjs/locale/zh-cn'
import { useEffect } from 'react'
import { connect } from 'react-redux'
import { SET_WINDOW_SIZE, GET_MENU, SET_NAV_MENUS } from './store/actions'
import router from './router'
import { RouterProvider } from 'react-router-dom'
import { TOKEN_CACHE_KEY } from '@/config'

dayjs.locale('zh')

function App(props) {

  useEffect(() => {
    props.setWindowSize()
    window.addEventListener('resize', props.setWindowSize)

    if (localStorage.getItem(TOKEN_CACHE_KEY)) {
      props.getMenu()
    }

    if (sessionStorage.getItem('navMenus')) {
      props.setNavMenus(JSON.parse(sessionStorage.getItem('navMenus')))
    }

    return () => {
      window.removeEventListener('resize', props.setWindowSize)
    }
    // eslint-disable-next-line
  }, [])

  return (
    <ConfigProvider locale={zhCN}>
      <AntdAppComponent>
        <RouterProvider router={router} />
      </AntdAppComponent>
    </ConfigProvider>
  )
}

const mapDispatchToProps = dispatch => {
  return {
    setWindowSize: () => dispatch(SET_WINDOW_SIZE()),
    getMenu: () => dispatch(GET_MENU()),
    setNavMenus: (data) => dispatch(SET_NAV_MENUS(data))
  }
}

export default connect(null, mapDispatchToProps)(App)
