/** 主题色 **/
export const MAIN_COLOR = '#1890ff'

/** token缓存名 **/
export const TOKEN_CACHE_KEY = 'shuju-life-admin-token'

/** 接口请求失败的默认提示消息 **/
export const DEFAULT_ERROR_MESSAGE = '系统繁忙，请稍后再试'

/** 窗口尺寸 **/
export const WINDOW_SIZE_SMALL = 640
export const WINDOW_SIZE_LARGE = 960
