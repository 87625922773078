import { useLocation, Navigate } from 'react-router-dom'
import { TOKEN_CACHE_KEY } from '@/config'

function AuthRouter ({ children }) {
  const { pathname } = useLocation()
  if (pathname === '/login' || localStorage.getItem(TOKEN_CACHE_KEY)) {
    return children
  }
  return <Navigate to="/login"></Navigate>
}

// eslint-disable-next-line
export default AuthRouter
