import { createBrowserRouter, Navigate } from 'react-router-dom'
import { lazy, Suspense } from 'react'
import Layout from '../layout/Index'
import AuthRouter from './AuthRouter'
import PageLoading from '../components/PageLoading'

const routes = [
  {
    path: '',
    component: () => <Navigate to="/layout/home" />
  },
  {
    path: '/login',
    component: lazy(() => import('../pages/Login'))
  },
  {
    path: '/layout',
    component: Layout,
    children: [
      /** 欢迎页 **/
      {
        path: 'home',
        component: lazy(() => import('../pages/Home'))
      },
      /** 代理商管理-代理商列表 **/
      {
        path: 'userManager',
        component: lazy(() => import('../pages/Agent/List'))
      },
      /** 代理商管理-添加或编辑代理商 **/
      {
        path: 'userManager/create-or-update',
        component: lazy(() => import('../pages/Agent/CreateOrUpdate'))
      },
      /** 代理商管理-用户账单明细 **/
      {
        path: 'userManager/userBill',
        component: lazy(() => import('../pages/Agent/UserBill'))
      },
      /** 提现管理-提现列表 **/
      {
        path: 'withdrawManager/withdrawListManager',
        component: lazy(() => import('../pages/Withdraw/List'))
      },
      /** 电费管理-订单列表 **/
      {
        path: 'powerManager',
        component: lazy(() => import('../pages/PowerFee/OrderList'))
      },
      /** 话费管理-订单列表 **/
      {
        path: 'huafManager',
        component: lazy(() => import('../pages/TelFee/OrderList'))
      },
      /** 充值记录 **/
      {
        path: 'depositManager',
        component: lazy(() => import('../pages/Deposit/Record'))
      },
      /** 产品规格管理 **/
      {
        path: 'specsManager',
        component: lazy(() => import('../pages/ProductSpecs/List'))
      },
      /** 产品规格管理 新增或编辑 **/
      {
        path: 'specsManager/create-or-update',
        component: lazy(() => import('../pages/ProductSpecs/CreateOrUpdate'))
      },
      /** 系统管理-修改密码 **/
      {
        path: 'systemManager/systemPasswordManager',
        component: lazy(() => import('../pages/System/ModifyPassword'))
      }
    ]
  },
  {
    path: '*',
    component: () => <div>404 not found</div>
  }
]

function generateRoutes (routes) {
  return routes.map(e => {
    if (e.children) {
      e.children = generateRoutes(e.children)
    }
    e.element = (
      <AuthRouter>
        <Suspense fallback={<PageLoading />}>
          <e.component />
        </Suspense>
      </AuthRouter>
    )
    return e
  })
}

const router = createBrowserRouter(generateRoutes(routes))

export default router
