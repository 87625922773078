import request from './request'

// 登录  测试账号 admin a11111111
export function login (data) {
  return request({
    url: '/v1/admin/user/login',
    method: 'post',
    data
  })
}

// 获取菜单
export function getMenu () {
  return request({
    url: '/v1/admin/user/menu'
  })
}

// 修改密码
export function modifyPassword (data) {
  return request({
    url: '/v1/admin/user/password',
    method: 'post',
    data
  })
}

// 代理商管理-代理商列表
export function getAgentList (params) {
  return request({
    url: '/v1/admin/manager/user/list',
    params
  })
}

// 代理商管理-充值
export function agentDeposit (data) {
  return request({
    url: '/v1/admin/manager/user/deposit',
    method: 'post',
    data
  })
}

// 代理商管理-操作代理商
export function operateAgent (data) {
  return request({
    url: '/v1/admin/manager/user/operate',
    method: 'post',
    data
  })
}

// 代理商管理-代理商详情
export function getAgentInfo (id) {
  return request({
    url: `/v1/admin/manager/user/info?id=${id}`
  })
}

// 代理商管理-代理商账单明细
export function getAgentBill (params) {
  return request({
    url: '/v1/admin/manager/user/bill',
    params
  })
}

// 代理商管理-代理商账单类型
export function getAgentBillTypes () {
  return request({
    url: '/v1/admin/manager/user/bill/types'
  })
}

// 话费充值-订单列表
export function getOrderListOfHf (params) {
  return request({
    url: '/v1/admin/manager/order/list',
    params
  })
}

// 话费充值-导出话费订单
export function exportHfOrder (params) {
  return request({
    url: '/v1/admin/manager/order/export',
    params,
    responseType: 'blob'
  })
}

// 话费充值-导出话费订单
export function exportPowerOrder (params) {
  return request({
    url: '/v1/admin/manager/power/export',
    params,
    responseType: 'blob'
  })
}

// 电费充值-订单列表
export function getOrderListOfPower (params) {
  return request({
    url: '/v1/admin/manager/power/list',
    params
  })
}

// 充值记录
export function getDepositRecord (params) {
  return request({
    url: '/v1/admin/manager/deposit/list',
    params
  })
}

// 提现管理-提现记录
export function getWithdrawList (params) {
  return request({
    url: '/v1/admin/manager/withdraw/list',
    params
  })
}

// 提现管理-提现订单操作
export function operateWithdrawOrder (data) {
  return request({
    url: '/v1/admin/manager/withdraw/operate',
    method: 'post',
    data
  })
}

// 话费订单操作
export function operateOrder (data) {
  return request({
    url: '/v1/admin/manager/order/operate',
    method: 'post',
    data
  })
}

// 话费订单操作
export function operatePowerOrder (data) {
  return request({
    url: '/v1/admin/manager/power/operate',
    method: 'post',
    data
  })
}

// 产品规格管理-规格列表
export function getProdSpecList (params) {
  return request({
    url: '/v1/admin/manager/specs/list',
    params
  })
}

// 产品规格管理-规格类型列表
export function getProdSpecTypes () {
  return request({
    url: '/v1/admin/manager/specs/type/list'
  })
}

// 产品规格管理-产品规格操作
export function operateProdSpec (data) {
  return request({
    url: '/v1/admin/manager/specs/operate',
    method: 'post',
    data
  })
}
