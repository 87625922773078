import './style.less'
// import { DotLoading } from 'antd-mobile'
// import { MAIN_COLOR } from '../../config'

function PageLoading () {
  return (
    <div className="page-loading">
      {/*<DotLoading color={MAIN_COLOR} />*/}
    </div>
  )
}

export default PageLoading
