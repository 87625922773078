import './style.less'
import Header from '../Header'
import Menu from '../Menu'
import { Drawer } from 'antd'
import { LeftOutlined } from '@ant-design/icons'
import { Outlet } from 'react-router-dom'
import { connect } from 'react-redux'
import { SET_M_MENU_OPENED } from '@/store/actions'

function Layout ({ isM, mMenuOpened, setMMenuOpened }) {

  const MobileSidebar = () => (
    <Drawer
      placement="left"
      width={220}
      bodyStyle={{
        padding: 0,
        background: 'var(--layout-bg)'
      }}
      open={mMenuOpened}
      onClose={() => setMMenuOpened(false)}
      closable={false}
    >
      <Menu />
      <div className="mobile-sidebar-close" onClick={() => setMMenuOpened(false)}>
        <LeftOutlined style={{fontSize: 20}} />
      </div>
    </Drawer>
  )

  const PcSidebar = () => (
    <div className="layout-sidebar"><Menu /></div>
  )

  return (
    <div className="layout">
      <Header />
      {isM ?  <MobileSidebar /> : <PcSidebar />}
      <div
        className="layout-main"
        style={{
          padding: isM ? 8 : 12,
          marginLeft: isM ? 0 : 220
        }}
      >
        <Outlet />
      </div>
    </div>
  )
}

const mapStateToProps = (state) => {
  return {
    isM: state.windowSize === 's',
    mMenuOpened: state.mMenuOpened
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    setMMenuOpened: (opened) => dispatch(SET_M_MENU_OPENED(opened))
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Layout)
