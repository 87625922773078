import axios from 'axios'
import { TOKEN_CACHE_KEY, DEFAULT_ERROR_MESSAGE } from '@/config'

const BASE_URL = process.env.NODE_ENV === 'development'
  ? 'https://api.daxiangjuhe.cn' // 开发环境接口请求地址
  : 'https://api.daxiangjuhe.cn' // 生产环境接口请求地址

const service = axios.create({
  baseURL: BASE_URL,
  withCredentials: false,
  timeout: 30000
})

service.interceptors.request.use(
  config => {
    const token = localStorage.getItem(TOKEN_CACHE_KEY)
    if (token) {
      config.headers.Authorization = token
    }
    return config
  },
  error => {
    return Promise.reject(error)
  }
)

service.interceptors.response.use(
  response => {
    const res = response.data
    if (response.config.responseType === 'blob') {
      return Promise.resolve(res)
    } else if (res.status === 200) {
      return Promise.resolve(res)
    } else if (res.status === 3906 || res.status === 40004) { // 3906：没有登录 40004：token过期
      localStorage.removeItem(TOKEN_CACHE_KEY)
      if (window.location.pathname !== '/login') {
        window.location.replace(window.location.origin + '/login')
      }
      return Promise.reject(res)
    } else if (res.message) {
      return Promise.reject(res)
    } else {
      return Promise.reject({ ...res, message: DEFAULT_ERROR_MESSAGE })
    }
  },
  error => {
    let message
    if (error.message && error.message.indexOf('timeout') > -1) {
      message = '请求超时，请稍后重试！'
    } else {
      message = '请求失败，请稍后重试！'
    }
    return Promise.reject({ message })
  }
)

export default service
